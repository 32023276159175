<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ salesPayment.name }}</span>
    </div>

    <div>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.DATE") }}</dt>
        <dd class="col-sm-8">
          {{ $formatDate(salesPayment.date) }}
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.INVOICE") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="salesPayment.invoice" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="salesPayment.allowedLocations" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="salesPayment.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <salesPayment-status-badge :salesPayment="salesPayment" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.AMOUNT") }}</dt>
        <dd class="col-sm-8">
          {{ $formatCurrency(salesPayment.amount) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("SALES_PAYMENTS.SOURCE") }}</dt>
        <dd class="col-sm-8">
          {{ $t(`SALES_PAYMENTS.SALES_PAYMENT_SOURCE_${salesPayment.source}`) }}
        </dd>
      </dl>

      <dl class="row" v-if="salesPayment.excerpt">
        <dt class="col-sm-4">
          {{ $t("COMMON.EXCERPT") }}
        </dt>
        <dd class="col-sm-8">
          <div v-html="salesPayment.excerpt"></div>
        </dd>
      </dl>

      <dl class="row" v-if="salesPayment.transaction_id">
        <dt class="col-sm-4">
          {{ $t("SALES_PAYMENTS.TRANSACTION_ID") }}
        </dt>
        <dd class="col-sm-8">
          {{ salesPayment.transaction_id }}
        </dd>
      </dl>

      <dl class="row" v-if="salesPayment.transaction_data">
        <dt class="col-sm-4">
          {{ $t("SALES_PAYMENTS.TRANSACTION_DATA") }}
        </dt>
        <dd class="col-sm-8">
          {{ salesPayment.transaction_data }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="salesPayment.created_at">
          {{ $formatDate(salesPayment.created_at) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="salesPayment.updated_at">
          {{ $formatDate(salesPayment.updated_at) }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import SalesPaymentStatusBadge from "./SalesPaymentStatusBadge.vue";
export default {
  name: "salesPayment-view-global",

  components: { SalesPaymentStatusBadge },

  props: ["salesPayment"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesPayment(salesPayment) {},
  },
};
</script>
